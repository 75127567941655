export const ADD_ENQUIRY_SUCCESS = "ADD_ENQUIRY_SUCCESS";
export const ADD_ENQUIRY_FAILURE = "ADD_ENQUIRY_FAILURE";
export const ADD_ENQUIRY_PENDING = "ADD_ENQUIRY_PENDING";

export const GET_ALL_ENQUIRIES_SUCCESS = "GET_ALL_ENQUIRIES_SUCCESS";
export const GET_ALL_ENQUIRIES_FAILURE = "GET_ALL_ENQUIRIES_FAILURE";
export const GET_ALL_ENQUIRIES_PENDING = "GET_ALL_ENQUIRIES_PENDING";

export const DELETE_ENQUIRY_PENDING = "DELETE_ENQUIRY_PENDING";
export const DELETE_ENQUIRY_SUCCESS = "DELETE_ENQUIRY_SUCCESS";
export const DELETE_ENQUIRY_FAILURE = "DELETE_ENQUIRY_FAILURE";
